import { PrinterIcon } from '@heroicons/react/solid'
import { graphql } from 'gatsby'
import BlockContent from 'gatsby-theme-tailwindui/src/components/block-content'
import Figure from 'gatsby-theme-tailwindui/src/components/block-content/Figure'
import ModularTextBlock from 'gatsby-theme-tailwindui/src/components/block-content/ModularTextBlock'
import GraphQLErrorList from 'gatsby-theme-tailwindui/src/components/graphql-error-lists'
import Layout from 'gatsby-theme-tailwindui/src/components/layout'
import ShuffleText from 'gatsby-theme-tailwindui/src/components/ShuffleText'
import TwoColumnsImage from 'gatsby-theme-tailwindui/src/components/tailwindUI/contentSections/twoColumnsImage'
import TwoColumnText from 'gatsby-theme-tailwindui/src/components/tailwindUI/contentSections/twoColumnText'
import CTA from 'gatsby-theme-tailwindui/src/components/tailwindUI/ctaSections/cta'
import Grid from 'gatsby-theme-tailwindui/src/components/tailwindUI/featuredSections/grid'
import Jotform from 'gatsby-theme-tailwindui/src/components/tailwindUI/forms/jotform'
import SimpleCentered from 'gatsby-theme-tailwindui/src/components/tailwindUI/headerSections/simpleCentered'
import HeroSplitContentWithImage from 'gatsby-theme-tailwindui/src/components/tailwindUI/heroes/split-content-with-image'
import PricingComparisonTable from 'gatsby-theme-tailwindui/src/components/tailwindUI/pricing/PricingComparisonTable'
import People from 'gatsby-theme-tailwindui/src/components/tailwindUI/teamSections/people'
import SEO from 'gatsby-theme-tailwindui/src/utils/seo'
import React from 'react'
import EmailFeed from '../../components/emailFeed'

// Custom Imports
import ContentGrid from '../../components/contentGrid'
import DidntMake from '../../components/didntMake'
import InvestedIn from '../../components/investedIn'
import PDFLoader from '../../components/PDFLoader/PDFLoader'
import QuoteSlider from '../../components/quoteSlider'
import WhatWelEarned from '../../components/whatWeLearned'
import PageHeader from '../components/PageHeader'
import Faq from '../components/tailwindUI/faq/faq'
import TestimonialGrid from '../components/tailwindUI/testimonials/layouts/side-by-side-on-brand'
import { useSiteMetadata } from '../utils/use-site-metadata'
// TailwindUIComponents:
export const query = graphql`
  query CustomPageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`

const PrimaryPage = (props) => {
  const { data, errors } = props
  const { globalContactMenu, globalNavMenu } = useSiteMetadata()
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const { site } = data || {}

  if (!site) {
    throw new Error('Page Template Error')
  }
  const page = data.page || data.route.page
  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'figure':
          el = <Figure key={c._key} {...c} />
          break
        case 'modularTextBlock':
          el = <ModularTextBlock key={c._key} {...c} blocks={c.text} />
          break
        case 'splitContentWithImage':
          el = <HeroSplitContentWithImage key={c._key} {...c} blocks={c.text} />
          break
        case 'ctaSimple':
          el = <CTA key={c._key} {...c} blocks={c.text} />
          break
        case 'people':
          el = <People key={c._key} {...c} blocks={c.text} />
          break
        case 'pricingComparisonTable':
          el = <PricingComparisonTable key={c._key} {...c} />
          break
        case 'simpleCentered':
          el = <SimpleCentered key={c._key} {...c} />
          break
        case 'shuffleTextBlock':
          el = <ShuffleText key={c._key} {...c} />
          break
        case 'twoColumnText':
          el = <TwoColumnText key={c._key} {...c} blocks={c.text} />
          break
        case 'twoColumnsImage':
          el = <TwoColumnsImage key={c._key} {...c} blocks={c.text} />
          break
        case 'featureGrid':
          el = <Grid key={c._key} {...c} />
          break
        case 'faqList':
          el = <Faq key={c._key} {...c} blocks={c.blockContent} />
          break
        case 'jotform':
          el = <Jotform key={c._key} {...c} blocks={c.blockContent} />
          break
        // Custom Imports
        case 'contentGrid':
          el = <ContentGrid key={c._key} {...c} />
          break
        case 'quoteSlider':
          el = <QuoteSlider key={c._key} {...c} />
          break
        case 'didntMake':
          el = <DidntMake key={c._key} {...c} />
          break
        case 'whatWeLearned':
          el = <WhatWelEarned key={c._key} {...c} />
          break
        case 'testimonialGrid':
          el = <TestimonialGrid key={c._key} {...c} />
          break
        case 'investedIn':
          el = <InvestedIn key={c._key} {...c} />
          break
        case 'emailFeed':
          el = <EmailFeed key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  const menuItems = page.navMenu?.items || globalNavMenu || []

  const contactMenu = page.contactMenu?.items || globalContactMenu || []
  // const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const pages = data.route?.page?.pdf?.pageCount || 1
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <SEO title="Lateral Capital" description="Lateral Capital Infomation" />
      <PageHeader propsHeader={page} />
      <div>{content}</div>

      {page._rawBlockContent && (
        <div>
          <BlockContent blocks={page._rawBlockContent} />
        </div>
      )}
      {data.route?.page?.pdf?.pdfFile ? (
        <div>
          <PDFLoader
            pages={pages}
            pdf={data.route.page?.pdf?.pdfFile?.asset.url}
          />

          <div className="w-max float-right my-4 ">
            <a
              href={data.route.page.pdf.pdfFile.asset.url}
              className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              rel="noopener noreferrer"
              target="_blank"
            >
              Print This Page
              <PrinterIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </a>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}

export default PrimaryPage
