import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../../utils/client-config'
import { buildImageObj } from '../../../utils/helpers'
import { imageUrlFor } from '../../../utils/image-url'

const HeroSplitContentWithImage = (props) => {
  const buttons = props?.buttons?.buttons
  const figure = props?.image
  let i = 0 // counter for all button margins except first button.
  return (
    <div className="relative bg-gray-50 split-content-with-image-variations hero-component">
      <main
        className={`lg:relative ${
          props.reverseColumns && 'flex flex-col-reverse'
        }`}
      >
        <div
          className={`mx-auto max-w-7xl w-full flex-grow ${
            props.size === 'small'
              ? 'pt-8 pb-10 lg:py-24'
              : 'pt-16 pb-20 lg:py-48'
          } text-center lg:text-left`}
        >
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block">{props.title1}</span>
              <span className="block text-indigo-700">{props.title2}</span>
            </h1>
            <p className="prose mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              {props.subTitle}
            </p>
            {buttons && (
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                {buttons
                  ? buttons.map((btn) => {
                      i += 1
                      return (
                        <div
                          key={btn._key}
                          className={`mt-3 rounded-md shadow ${
                            i !== 1 ? 'sm:ml-3' : null
                          }`}
                        >
                          <a
                            href={btn.href}
                            className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md ${
                              btn.buttonStyle === 'dark'
                                ? `text-white bg-indigo-600 hover:bg-indigo-700`
                                : `text-indigo-600 bg-white hover:bg-gray-50`
                            } md:py-4 md:text-lg md:px-10`}
                          >
                            {btn.buttonTxt}
                          </a>
                        </div>
                      )
                    })
                  : null}
              </div>
            )}
          </div>
        </div>
        <div className="relative overflow-hidden w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          {figure.disableGatsbyImage != true ? (
            <GatsbyImage
              layout="fixed"
              image={getGatsbyImageData(
                figure.asset.id,
                { maxWidth: 1024 },
                { ...clientConfig.sanity }
              )}
              className="absolute inset-0 w-full h-full object-cover"
              alt={props.alt || 'image'}
              loading="lazy"
            />
          ) : (
            <img
              src={imageUrlFor(buildImageObj(figure)).url()}
              alt={props.alt || 'image'}
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>
      </main>
    </div>
  )
}

export default HeroSplitContentWithImage
