// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'

const Simple = (props) => {
  console.log('CTA Simple Buttons', props)
  const { buttons } = props.buttons || []
  const { style } = props
  const bgc = style === 'dark' ? 'bg-gray-900' : 'bg-white'
  const txtC = style === 'dark' ? 'text-white' : 'text-gray-900'
  return (
    <div className={`${bgc} mb-8 cta-component`}>
      <div
        className={`max-w-7xl mx-auto ${
          !props.justified && 'text-center'
        } py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ${
          props.justified && 'lg:flex lg:items-center lg:justify-between'
        }`}
      >
        <h2
          className={`text-3xl font-extrabold tracking-tight ${txtC} sm:text-4xl`}
        >
          <span className="block">{props.titleLine1}</span>
          <span className="block text-indigo-600">{props.titleLine2}</span>
        </h2>
        {buttons ? (
          <div
            className={`mt-8 flex ${
              props.justified ? 'lg:mt-0 lg:flex-shrink-0' : 'justify-center'
            }`}
          >
            {buttons
              ? buttons.map((btn, i) => (
                  <div
                    key={i}
                    className={`mt-3 rounded-md shadow ${
                      i !== 0 ? 'sm:ml-3' : null
                    }`}
                  >
                    <a
                      href={(btn.href && btn.href) || '#'}
                      className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md ${
                        btn.buttonStyle === 'dark'
                          ? `text-white bg-gray-600 hover:bg-gray-700`
                          : `text-gray-600 bg-white hover:bg-gray-50`
                      } md:py-4 md:text-lg md:px-10`}
                    >
                      {(btn.buttonTxt && btn.buttonTxt) || 'button text needed'}
                    </a>
                  </div>
                ))
              : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Simple
