// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from '../../../block-content'
import clientConfig from '../../../../utils/client-config'

const LayoutSmallImages = (props) => {
  const p = props
  const { people } = props
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {props.title}
            </h2>
            <p className="text-xl text-gray-500">
              <BlockContent blocks={props.txt} />
            </p>
          </div>
          {people ? (
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                {people.map((person) => (
                  <li>
                    <div className="flex items-center space-x-4 lg:space-x-6">
                      {/* Image */}
                      <div className="w-16 h-16 rounded-full lg:w-20 lg:h-20 overflow-hidden">
                        <GatsbyImage
                          image={getFluidGatsbyImage(
                            person.image,
                            { maxWidth: 50 },
                            clientConfig.sanity
                          )}
                          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                          alt="alt" />
                      </div>
                      {/* End Image */}
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h3>{person.personName}</h3>
                        <p className="text-indigo-600">{person.name2}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            'none'
          )}
        </div>
      </div>
    </div>
  );
}

export default LayoutSmallImages
