// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Icon from '../../../../../assets/lateral-icon.svg'

export default function Testimonias(props) {
  return (
    <StaticQuery
      query={graphql`
        query TestimonialQuery {
          allSanitySimpleTestimonial {
            edges {
              node {
                testimonial
                author
              }
            }
          }
        }
      `}
      render={(data) => {
        const testomonial = data.allSanitySimpleTestimonial.edges
        return (
          <section className="bg-indigo-400 py-12">
            <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
              {testomonial.map((item, i) => (
                <div className="m-2  lg:p-6 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border md:border-indigo-900 lg:pr-16 bg-white">
                  <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                    <div className="relative text-lg font-medium  md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-24 w-24 text-gray-100 -mt-8"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative">{item.node.testimonial}</p>
                    </div>
                    <footer className="mt-8">
                      <div className="flex items-start">
                        <div className="flex-shrink-0 inline-flex ">
                          <Icon className="w-6" />
                        </div>
                        <div className="ml-4">
                          <div className="text-base font-medium">
                            {item.node.author}
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              ))}
            </div>
          </section>
        )
      }}
    />
  )
}
