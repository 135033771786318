import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../utils/client-config'

const PageHeader = (props) => {
  const { featuredImage } = props.propsHeader
  const { disableHeader } = props.propsHeader
  const { disableTitle } = props.propsHeader
  const image = featuredImage?.asset?.gatsbyImageData
  return (
    <>
      {image && disableHeader !== true && (
        <div className="relative bg-indigo-800">
          <div className="absolute inset-0">
            <GatsbyImage
              className="w-full h-full object-cover"
              image={image}
              alt="Lateral Capital Photo"
              loading="lazy"
            />
            <div
              className="absolute inset-0 bg-indigo-500"
              style={{ mixBlendMode: 'multiply' }}
              aria-hidden="true"
            />
          </div>

          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            {disableTitle !== true && (
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                {props.propsHeader.title}
              </h1>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default PageHeader
