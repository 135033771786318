import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import clientConfig from '../../../utils/client-config'
import serializers from '../../../utils/serializers'

const TwoColumnText = (props) => {
  const { buttons } = props.buttons ? props.buttons : 'no buttons'
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto">
        <div className="relative mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            {props.preTitle ? (
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                {props.preTitle}
              </h2>
            ) : null}
            {props.title ? (
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {props.title}
              </p>
            ) : null}
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:hidden absolute top-0 right-0 -mt-20 -mr-20"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <BaseBlockContent
              className="prose max-w-full w-full prose-indigo prose-lg text-gray-500 lg:max-w-full  column-1 md:column-2 column-gap-12"
              blocks={props.blockContent}
              serializers={serializers}
              {...clientConfig.sanity}
            />
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              {buttons ? (
                <div
                  className={`mt-8 flex ${
                    props.justified
                      ? 'lg:mt-0 lg:flex-shrink-0'
                      : 'justify-center'
                  }`}
                >
                  {buttons
                    ? buttons.map((btn, i) => (
                        <div
                          key={i}
                          className={`mt-3 rounded-md shadow ${
                            i !== 0 ? 'sm:ml-3' : null
                          }`}
                        >
                          <a
                            href={(btn.href && btn.href) || '#'}
                            className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md ${
                              btn.buttonStyle === 'dark'
                                ? `text-white bg-indigo-600 hover:bg-indigo-700`
                                : `text-indigo-600 bg-white hover:bg-gray-50`
                            } md:py-4 md:text-lg md:px-10`}
                          >
                            {(btn.buttonTxt && btn.buttonTxt) ||
                              'button text needed'}
                          </a>
                        </div>
                      ))
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColumnText
