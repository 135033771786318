import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { PrinterIcon } from "@heroicons/react/solid"
import BaseBlockContent from "@sanity/block-content-to-react"
import clientConfig from "gatsby-theme-tailwindui/src/utils/client-config"
import serializers from "gatsby-theme-tailwindui/src/utils/serializers"
import React from "react"
import PDFLoader from "../../../../components/PDFLoader/PDFLoader"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Faq = (props) => {
  const { faq } = props
  return (
    <div className="bg-gray-50 faq-section">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faq.map((item, i) => (
              <Disclosure as="div" key={i} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {item.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <div className="text-base text-gray-500 overflow-hidden">
                        {!item.pdf ? (
                          <BaseBlockContent
                            blocks={item.blockContent}
                            serializers={serializers}
                            {...clientConfig.sanity}
                          />
                        ) : null}
                        {open && (
                          <>
                            <PDFLoader
                              pdf={item.pdf.pdfFile.asset.url}
                              pages={1}
                            />

                            <div className="w-max float-right my-4 ">
                              <a
                                href={item.pdf.pdfFile.asset.url}
                                className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Print This FAQ
                                <PrinterIcon
                                  className="ml-3 -mr-1 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Faq
