import React from 'react'
import PricingComparisonTableLarge from './large'
import PricingComparisonTableSmall from './small'

export default function PricingComparisonTable(props) {
  if (!props.categories || !props.plans) {
    return <div>PricingComparisonTable is missing data.</div>
  }
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        <PricingComparisonTableLarge {...props} />
        <PricingComparisonTableSmall {...props} />
      </div>
    </div>
  )
}
