// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import OffsetImg from './layouts/offsetImg'
import Simple from './layouts/simple'
import LayoutSplitImage from './layouts/splitImage'

const CTA = (props) => {
  const { layout } = props
  switch (layout) {
    case 'offsetImg':
      return <OffsetImg {...props} />
    case 'simple':
      return <Simple {...props} />
    case 'splitImage':
      return <LayoutSplitImage {...props} />
    default:
      return <div>CTA: No Layout Selected</div>
  }
}

export default CTA
