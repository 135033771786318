// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from '../../../block-content'
import clientConfig from '../../../../utils/client-config'
import { buildImageObj } from '../../../../utils/helpers'
import { imageUrlFor } from '../../../../utils/image-url'
import serializers from '../../../../utils/serializers'

const SplitImage = (props) => {
  const { buttons } = props.buttons
  return (
    <div className="relative bg-gray-900 cta-component">
      <div className="h-56 bg-gray-200 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        {/* {props.image.disableGatsbyImage != true ? (
          <Img
            fluid={getFluidGatsbyImage(
              props.image,
              { maxWidth: 800 },
              clientConfig.sanity
            )}
            alt={props.alt}
            loading="lazy"
            className="w-full h-full object-cover"
          />
        ) : (
          <img
            className="w-full h-full object-cover"
            src={imageUrlFor(buildImageObj(props.image)).url()}
            alt={props.alt}
          />
        )} */}

        <img
          className="w-full h-full object-cover"
          src={imageUrlFor(buildImageObj(props.image)).url()}
          alt={props.alt}
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
            {props.titleLine1}
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            {props.titleLine2}
          </p>
          <p className="mt-3 text-lg text-gray-300">{props.txt}</p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              {buttons ? (
                <div
                  className={`mt-8 flex ${
                    props.justified
                      ? 'lg:mt-0 lg:flex-shrink-0'
                      : 'justify-center'
                  }`}
                >
                  {buttons
                    ? buttons.map((btn, i) => (
                        <div
                          key={i}
                          className={`mt-3 rounded-md shadow ${
                            i !== 0 ? 'sm:ml-3' : null
                          }`}
                        >
                          <a
                            href={(btn.href && btn.href) || '#'}
                            className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md ${
                              btn.buttonStyle === 'dark'
                                ? `text-white bg-gray-600 hover:bg-gray-700`
                                : `text-gray-600 bg-white hover:bg-gray-50`
                            } md:py-4 md:text-lg md:px-10`}
                          >
                            {(btn.buttonTxt && btn.buttonTxt) ||
                              'button text needed'}
                          </a>
                        </div>
                      ))
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitImage
