// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { getFixedGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from '../../../block-content'
import clientConfig from '../../../../utils/client-config'
import { buildImageObj } from '../../../../utils/helpers'
import { imageUrlFor } from '../../../../utils/image-url'

const LayoutVerticalImages = (props) => {
  const { people } = props
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-24 lg:pt-6">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {props.title}
            </h2>
            <p className="text-xl text-gray-500">
              <BlockContent blocks={props.txt} />
            </p>
          </div>
          {people ? (
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
                {people.map((person) => (
                  <li className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      {/* Image */}
                      <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4 overflow-hidden rounded-lg">
                        <div className="object-cover flex w-max">
                          {/* <Img
                            className="shadow-lg rounded-lg object-cover w-100"
                            fixed={getFixedGatsbyImage(
                              person.image,
                              { maxWidth: 800 },
                              clientConfig.sanity
                            )}
                            alt="alt"
                          /> */}
                          <img
                            src={imageUrlFor(buildImageObj(person.image)).url()}
                            alt={props.alt}
                            className="shadow-lg rounded-lg object-cover w-100"
                          />
                        </div>
                      </div>
                      {/* End Image */}
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.personName}</h3>
                            <p className="text-indigo-600">{person.name2}</p>
                          </div>
                          <div className="text-lg text-gray-500">
                            <BlockContent blocks={person.blockContent} />
                          </div>
                          {/* Start Contact Links */}
                          <div>
                            {person.contact
                              ? person.contact.map((option) => (
                                  <div>{option.name}</div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            'none'
          )}
        </div>
      </div>
    </div>
  )
}

export default LayoutVerticalImages
