// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import BlockContent from '../../../block-content'
import clientConfig from '../../../../utils/client-config'
import { buildImageObj } from '../../../../utils/helpers'
import { imageUrlFor } from '../../../../utils/image-url'
import serializers from '../../../../utils/serializers'

const OffsetImg = (props) => {
  const { buttons } = props
  const figure = props?.image
  let i = 0
  return (
    <div className="bg-white cta-component">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">{props?.titleLine1}</span>
                <span className="block">{props?.titleLine2}</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
                Malesuada adipiscing sagittis vel nulla nec.
              </p>
              {buttons
                ? buttons.map((btn) => {
                    i += 1
                    return (
                      <a
                        href={(btn.href && btn.href) || '#'}
                        className={`mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium rounded-md ${
                          btn.buttonStyle === 'dark'
                            ? `text-white bg-indigo-600 hover:bg-indigo-700`
                            : `text-indigo-600 bg-white hover:bg-gray-50`
                        } md:py-4 md:text-lg md:px-10`}
                      >
                        {(btn.buttonTxt && btn.buttonTxt) ||
                          'button text needed'}
                      </a>
                    )
                  })
                : null}
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            {/* Image */}
            <div className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20">
              {figure?.disableGatsbyImage != true ? (
                <GatsbyImage
                  layout="fixed"
                  image={getGatsbyImageData(
                    figure,
                    { maxWidth: 1024 },
                    { ...clientConfig.sanity }
                  )}
                  className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                  alt={props.alt}
                  loading="lazy"
                />
              ) : (
                // <GatsbyImage
                //   image={getFluidGatsbyImage(
                //     figure || null,
                //     { maxWidth: 800 },
                //     clientConfig.sanity
                //   )}
                //   alt={props.alt}
                //   loading="lazy"
                //   className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                // />
                <img
                  className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                  src={imageUrlFor(buildImageObj(figure)).url()}
                  alt={props.alt}
                />
              )}
            </div>
            {/* End Image */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OffsetImg
