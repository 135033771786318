// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import LayoutGridLargeImages from './layouts/gridLargeImages'
import LayoutGridSmallImages from './layouts/gridSmallImages'
import LayoutSmallImages from './layouts/smallImages'
import LayoutVerticalImages from './layouts/verticalImages'

const People = (props) => {
  const { people } = props
  const { layout } = props
  switch (layout) {
    case 'sImg':
      return <LayoutSmallImages {...props} />
    case 'lImg':
      return <LayoutVerticalImages {...props} />
    case 'gridSmallImg':
      return <LayoutGridSmallImages {...props} />
    case 'gridLargeImg':
      return <LayoutGridLargeImages {...props} />
    case 'imgBio':
      return <LayoutVerticalImages {...props} />
    case 'vertImgBio':
      return <LayoutVerticalImages {...props} />
    case 'fullWidthVertImg':
      return <LayoutVerticalImages {...props} />
    default:
  }
}

export default People
