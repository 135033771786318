import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import featuredImage from '../../studio/schemas/objects/featuredImage'

// function getFeaturedImage(rawItem, pages) {
//   const found = pages.find((page) => page.node._id === rawItem.page._id)
//   return found?.node?.featuredImage
// }

export default function ContentGrid(props) {
  const items = props.grid
  return (
    <StaticQuery
      query={graphql`
        query EmailQuery {
          allSanityEmails {
            edges {
              node {
                _id
                title
                description
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const contentGrid = data.allSanityEmails.edges
        return (
          <div className="relative max-w-7xl mx-auto pb-40">
            <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-2 lg:max-w-none">
              {contentGrid.map((item, i) => (
                <div
                  key={`${item.title}-${i}`}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="prose flex-1 bg-white p-6 flex flex-col flex-start">
                    <div className="flex items-center flex-start w-full ">
                      <div className="">
                        <h4 className="prose font-medium text-indigo-600 p-0 mt-0">
                          {item.node.title}
                        </h4>
                        <p>{item.node.description}</p>
                        <div className="w-full text-left ">
                          <div className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 no-underline">
                            <a
                              className="text-white"
                              style={{
                                color: 'white',
                                textDecoration: 'none',
                                border: 'none',
                              }}
                              href={item.node.url}
                            >
                              View Email
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }}
    />
  )
}
