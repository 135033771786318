// https://tailwindui.com/components/marketing/sections/contact-sections
import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import JotformEmbed from 'react-jotform-embed'
import clientConfig from '../../../../utils/client-config'
import serializers from '../../../../utils/serializers'
import { imageUrlFor } from '../../../../utils/image-url'
import { buildImageObj } from '../../../../utils/helpers'

const SplitWithImage = (props) => {
  const { figure } = props
  return (
    <>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            {/* {figure.disableGatsbyImage != true ? (
              <Img
                fluid={getFluidGatsbyImage(
                  figure,
                  { maxWidth: 800 },
                  clientConfig.sanity
                )}
                alt={props.alt}
                loading="lazy"
                className="h-56 w-full object-cover lg:absolute lg:h-full"
              />
            ) : (
              <img
                className="h-96 w-full object-cover lg:absolute lg:h-full"
                src={imageUrlFor(buildImageObj(figure)).url()}
                alt={props.alt}
              />
            )} */}
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={imageUrlFor(buildImageObj(figure)).url()}
              alt={props.alt}
            />
          </div>
        </div>
        <div className="relative py-24 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                {props.formTitle}
              </h2>
              <BaseBlockContent
                className="prose max-w-full w-full prose-indigo prose-lg text-gray-500 lg:max-w-full  column-1 md:column-2 column-gap-12"
                blocks={props.txt}
                serializers={serializers}
                {...clientConfig.sanity}
              />
              <JotformEmbed src={props.formURL} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SplitWithImage
