import React, { useState } from 'react'
import TextScramble from './textShuffleMaster'

function ShuffleText(props) {
  const { textSection } = props
  return textSection.map((shuffler, i) => {
    const Style = shuffler.textStyle || 'span'
    const [pause] = useState(false)
    return (
      <div className="mx-auto p-20 text-center" key={`shuffler_${i}`}>
        <Style>
          {shuffler.phrase
            ? shuffler.phrase.map((section, i) => (
                <div key={`section_${i}`}>
                  {section.string ? (
                    <span className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                      {section.string} &nbsp;
                    </span>
                  ) : (
                    <section.textStyle
                      className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-primary"
                      sx={{
                        fontSize: section.textSize ? section.textSize : 1,
                      }}
                      key={`shuffler_${i}`}
                    >
                      <TextScramble
                        texts={section.text}
                        letterSpeed={5}
                        nextLetterSpeed={100}
                        pauseTime={3000}
                        paused={pause}
                      />
                      &nbsp;
                    </section.textStyle>
                  )}
                </div>
              ))
            : null}
        </Style>
      </div>
    )
  })
}

export default ShuffleText
