import Icons from "../Icons"

const YesIcon = Icons("FaCheck", {
  style: {
    color: "#0A0",
  },
})
const NoIcon = Icons("FaTimes", {
  style: {
    color: "#A00",
  },
})
const DashIcon = Icons("FaMinus", {
  style: {
    color: "#999",
  },
})

const iconMap = {
  yes: YesIcon,
  no: NoIcon,
  dash: DashIcon,
}

export default iconMap
