// https://tailwindui.com/components/application-ui/navigation/navbars#component-70a9bdf83ef2c8568c5cddf6c39c2331
// Simple dark with menu button on left
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from '../../../block-content'
import clientConfig from '../../../../utils/client-config'
import { buildImageObj } from '../../../../utils/helpers'
import { imageUrlFor } from '../../../../utils/image-url'

const LayoutGridLargeImages = (props) => {
  const p = props
  const { people } = props
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {p.title}
            </h2>
            <p className="text-xl text-gray-500">
              <BlockContent blocks={p.txt} />
            </p>
          </div>
          {people ? (
            <div className="lg:col-span-2">
              <ul className="gap-8 grid-cols-2 mx-auto space-y-16 grid sm:grid-cols-3 sm:gap-16 sm:space-y-0 lg:max-w-5xl">
                {people.map((person) => (
                  <li>
                    <div className="space-y-6  object-cover">
                      {/* Image */}
                      {/* <Img
                        fluid={getFluidGatsbyImage(
                          person.image,
                          { width: 160 },
                          clientConfig.sanity
                        )}
                        className={`mx-auto rounded-full xl:w-56 xl:h-56 ${
                          person.imageShape === 'rounded' ? 'hidden' : null
                        }`}
                        alt="alt"
                      /> */}
                      {person.image && (
                        <img
                          src={imageUrlFor(buildImageObj(person.image)).url()}
                          alt={props.alt}
                          className={`mx-auto rounded-md shadow-md w-56   ${
                            person.imageShape === 'rounded' ? 'hidden' : null
                          }`}
                        />
                      )}
                      {/* End Image */}
                      <div className="space-y-2">
                        <div className="text-xs font-medium lg:text-sm">
                          <h3>{person.personName}</h3>
                          {person.name2 && (
                            <p className="text-indigo-600 p-txt-1">
                              {person.name2}
                            </p>
                          )}
                          {person.name3 && (
                            <p className="text-indigo-600 p-txt-2">
                              {person.name3}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            'none'
          )}
        </div>
      </div>
    </div>
  )
}

export default LayoutGridLargeImages
