import React, { useCallback } from 'react'
import DOM from 'react-dom'
import * as styles from './Printable.module.css'
import './Printable.global.css'

export default function Printable(props) {
  const print = useCallback(() => {
    const root = document.body.querySelector('#___gatsby')
    root.style.display = 'none'

    const newElem = document.createElement('div')
    newElem.className = '__printable__'

    document.body.appendChild(newElem)
    DOM.render(<div>{props.children}</div>, newElem)
    setTimeout(() => {
      window.print() // SYNC CODE
      DOM.unmountComponentAtNode(newElem)
      root.style.removeProperty('display')
    }, 500) // we need a bit of time to let the browser render images
  }, [props])
  return (
    <div>
      <div>{props.children}</div>
      <button
        className="relative float-right -mt-20 mr-12"
        onClick={print}
        type="button"
      >
        PRINT
      </button>
    </div>
  )
}
