import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import clientConfig from '../../utils/client-config'
import serializers from '../../utils/serializers'

const ModularTextBlock = ({ blocks }) => (
  <div className="relative px-4 sm:px-6 lg:px-8">
    <div className="prose text-lg text-justify max-w-prose mx-auto c32ContentBlock">
      <BaseBlockContent
        blocks={blocks}
        serializers={serializers}
        {...clientConfig.sanity}
      />
    </div>
  </div>
)

export default ModularTextBlock
