// https://tailwindui.com/components/marketing/sections/content-sections
// Two Columns With Image
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import BaseBlockContent from '@sanity/block-content-to-react'
import Video from '../../video/video'
import clientConfig from '../../../utils/client-config'
import { buildImageObj } from '../../../utils/helpers'
import { imageUrlFor } from '../../../utils/image-url'
import serializers from '../../../utils/serializers'

const TwoColumnsImage = (props) => {
  const { figure } = props
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h2 className=" relative text-base text-indigo-600 font-semibold tracking-wide uppercase">
            {props.preTitle}
          </h2>
          <h3 className="  relative mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {props.title}
          </h3>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:hidden absolute top-0 right-0 -mt-20 -mr-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                className="-z-10"
                width="404"
                height="384"
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto">
              {props.video ? (
                <div className="rounded-lg shadow-lg object-contain object-center overflow-hidden max-w-max mx-auto ">
                  <Video
                    containerClasses="inset max-w-full mx-auto max-w-max"
                    height={props.video?.height || null}
                    width={props.video?.width || null}
                    // autoPlay
                    muted
                    loop
                    url={props.video?.url}
                    service={props.video?.service}
                  />
                </div>
              ) : null}
              {props?.figure?.alt ? (
                <figure>
                  <div
                    className={`${
                      props.imageSize === 'l'
                        ? 'aspect-w-12 aspect-h-7'
                        : 'aspect-w-12 aspect-h-7'
                    } lg:aspect-none`}
                    xx
                  >
                    {figure.disableGatsbyImage != true ? (
                      <div className="rounded-lg shadow-lg object-contain object-center overflow-hidden">
                        <GatsbyImage
                          image={getGatsbyImageData(
                            figure?.asset?.id,
                            { maxWidth: 1024 },
                            { ...clientConfig.sanity }
                          )}
                          alt={props?.alt || 'image'}
                          loading="lazy"
                        />
                      </div>
                    ) : (
                      <img
                        className="object-contain object-center max-h-96"
                        src={imageUrlFor(buildImageObj(figure)).url()}
                        alt={props?.alt || 'image'}
                      />
                    )}
                  </div>
                </figure>
              ) : null}
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <BaseBlockContent
                blocks={props.blockContent}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColumnsImage
