import React from "react"
import iconMap from "./icons"

function renderRows(currentPlan, currentCategory) {
  return currentCategory.features.map((feature, i) => {
    return (
      <tr key={i} className="border-t border-gray-200">
        <th
          className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
          scope="row"
        >
          {feature.title}
        </th>
        <td className="py-5 pr-4">{renderCell(currentPlan, feature)}</td>
      </tr>
    )
  })
}

function renderCell(plan, feature) {
  const featureValue = plan.featuresValues.find(
    (featValue) => featValue.feature.id === feature.id
  )

  if (!featureValue) {
    return iconMap.dash
  }

  const icon = iconMap[featureValue.icon]

  return (
    <div className="flex items-center justify-end">
      {featureValue.value && (
        <span className="block text-sm text-gray-700 mr-5">
          {featureValue.value}
        </span>
      )}
      {icon && <span className=" block"> {icon}</span>}
    </div>
  )
}

export default function PricingComparisonTableSmall(props) {
  const { plans, categories } = props

  return (
    <div className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto lg:hidden">
        {plans.map((plan, i) => (
          <div key={i}>
            <div className="px-4 mt-16">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                {plan.title}
              </h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">
                  {plan.cost}
                </span>
                <span className="text-base font-medium text-gray-500">
                  /{plan.costType}
                </span>
              </p>
              <p className="mt-4 text-sm text-gray-500">{plan.description}</p>
              <a
                href="#"
                className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                TODO: Buy {plan.title}
              </a>
            </div>
            <div className="mt-8" />
            {categories.map((category, i) => (
              <table key={i} className="w-full">
                <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                  {category.title}
                </caption>
                <thead>
                  <tr>
                    <th className="sr-only" scope="col">
                      Feature
                    </th>
                    <th className="sr-only" scope="col">
                      Included
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {renderRows(plan, category)}
                </tbody>
              </table>
            ))}

            <div className="border-t border-gray-200 px-4 pt-5">
              <a
                href="#"
                className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Buy Premium
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
