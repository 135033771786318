import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import featuredImage from '../../studio/schemas/objects/featuredImage'

// function getFeaturedImage(rawItem, pages) {
//   const found = pages.find((page) => page.node._id === rawItem.page._id)
//   return found?.node?.featuredImage
// }

export default function InvestedIn(props) {
  const items = props.grid
  return (
    <StaticQuery
      query={graphql`
        query investedInQuery {
          logos: allSanityCompany {
            edges {
              node {
                featuredImage {
                  asset {
                    gatsbyImageData
                    url
                  }
                }
                text
                title
                url
                noBackground
              }
            }
          }
        }
      `}
      render={(data) => {
        const contentGrid = data.logos.edges
        return (
          <div className="max-w-7xl mx-auto my-12">
            <div className="mt-6 grid grid-cols-2 gap-10 md:grid-cols-2 lg:mt-8">
              {contentGrid.map((item) => {
                return (
                  <div className="col-span-1 flex flex-col items-center justify-center pt-8 pb-4 px-8 bg-gray-300">
                    <div>
                      <a href={item.node.url}>
                        <img
                          className="max-h-32 h-32"
                          src={item.node.featuredImage?.asset?.url}
                          alt=""
                          style={
                            item.node.noBackground === true
                              ? { mixBlendMode: 'multiply' }
                              : {}
                          }
                        />
                      </a>
                    </div>
                    {item.node.text && (
                      <p className="mt-4 w-full text-center">
                        <span className="bg-gray-200 txt- p-2 -mr-5 text-gray-500">
                          {item.node.text}
                        </span>
                      </p>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}
