// https://tailwindui.com/components/marketing/sections/feature-sections
// Simple dark with menu button on left
import React from 'react'
import Centered2x2Grid from './layouts/centered2x2Grid'
import FeatureGridList from './layouts/featureGridList'
import GridSmallImg from './layouts/gridSmallImg'
import Offset2x2Grid from './layouts/offset2x2Grid'
import SimpleThreeColumn from './layouts/simpleThreeColumn'
import Offset4x2Grid from './layouts/offset4x2Grid'
import OffsetIcons from './layouts/offsetIcons'

const Grid = (props) => {
  const { layout } = props
  switch (layout) {
    case 'centered2x2Grid':
      return <Centered2x2Grid {...props} />
    case 'featureGridList':
      return <FeatureGridList {...props} />
    case 'gridSmallImg':
      return <GridSmallImg {...props} />
    case 'offset2x2Grid':
      return <Offset2x2Grid {...props} />
    case 'simpleThreeColumn':
      return <SimpleThreeColumn {...props} />
    case 'offset4x2Grid':
      return <Offset4x2Grid {...props} />
    case 'offsetIcons':
      return <OffsetIcons {...props} />
    default:
      return <GridSmallImg {...props} />
  }
}

export default Grid
