import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './QuoteComponent.module.css'
import Printable from './Printable'
import Logo from '../../assets/lc-logo.svg'

export default function QuoteComponent(props) {
  const { data } = props
  return (
    <Printable>
      <div className={`${styles.QuoteComponent} mx-6`}>
        <div className={styles.border}>
          <div className={styles.border}>
            <div className={styles.border}>
              <div className={styles.content}>
                <div className={`${styles.quote} text-left`}>
                  {data.testimonial}
                </div>
                <div className={styles.authorBlock}>
                  <div className="mr-4">
                    {data.author.featuredImage ? (
                      <img
                        className={styles.image}
                        src={data.author?.featuredImage?.asset?.url}
                      />
                    ) : null}
                  </div>
                  <div className={styles.authorTexts}>
                    <div className={styles.authorName}>{data.author.name}</div>
                    <div className={styles.authorTitle}>
                      {data.author.authorInfo}
                    </div>
                  </div>
                </div>
                <div className="w-40 absolute bg-white mt-10">
                  <Logo />
                </div>
              </div>
              {/* <div className="float-right -mt-10 mr-5">
                <Link to="">print </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Printable>
  )
}
