import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import featuredImage from '../../studio/schemas/objects/featuredImage'

function getFeaturedImage(rawItem, pages) {
  const found = pages.find((page) => page.node._id === rawItem.page._id)
  return found?.node?.featuredImage
}

export default function ContentGrid(props) {
  const items = props.grid
  return (
    <StaticQuery
      query={graphql`
        query GridQuery {
          allSanityPage {
            edges {
              node {
                _id
                title
                featuredImage {
                  asset {
                    id
                    gatsbyImageData(width: 450)
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const contentGrid = data.allSanityPage.edges
        return (
          <div className="relative max-w-7xl mx-auto">
            <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
              {items.map((item, i) => {
                const featuredImage = getFeaturedImage(item, contentGrid)
                const image = getImage(featuredImage?.asset?.gatsbyImageData)
                return (
                  <Link to={item.slug.current} key={`item-${i}`}>
                    <div className="flex flex-col rounded-lg shadow-lg hover:shadow-sm overflow-hidden duration-150	">
                      <div className="flex-shrink-0">
                        {item.page.featuredImage ? (
                          <div className="h-48 w-full object-cover shadow-lg">
                            <GatsbyImage
                              className="h-48 w-full object-cover"
                              image={image}
                              alt="alt"
                            />
                          </div>
                        ) : (
                          <img
                            className="h-48 w-full object-cover"
                            src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixqx=DGp9TbiLnL&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                        <div className="flex-1">
                          <h4 className="font-medium py-0 text-black">
                            {/* <GatsbyImage image={item.featuredImage.asset.GatsbyImage} alt="alt" /> */}
                            {item.page.title}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

// const ContentGrid = (props) => {
//   const items = props.grid
//   return (
//     <div className="relative max-w-7xl mx-auto">
//       <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
//         {items.map((item) => {
//           console.log('These are the item props:')
//           console.log(item)
//           const image = getImage(item.page.featuredImage)
//           return (
//             <Link to={item.slug.current} className="hover:underline">
//               <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
//                 <div className="flex-shrink-0">
//                   {item.page.featuredImage ? (
//                     <div className="h-48 w-full object-cover">
//                       <GatsbyImage image={image} alt="alt" />
//                     </div>
//                   ) : (
//                     <img
//                       className="h-48 w-full object-cover"
//                       src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixqx=DGp9TbiLnL&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
//                       alt=""
//                     />
//                   )}
//                 </div>
//                 <div className="flex-1 bg-white p-6 flex flex-col justify-between">
//                   <div className="flex-1">
//                     <h4 className="text-sm font-medium text-indigo-600">
//                       {/* <GatsbyImage image={item.featuredImage.asset.GatsbyImage} alt="alt" /> */}
//                       {item.page.title}
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           )
//         })}
//       </div>
//     </div>
//   )
// }

// export default ContentGrid
