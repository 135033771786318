import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import QuoteComponent from './quotes/QuoteComponent'

export default function QuoteSlider(props) {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allSanityTestimonial {
            edges {
              node {
                testimonial
                testimonialCategory {
                  _id
                  title
                }
                author {
                  name
                  featuredImage {
                    asset {
                      url
                      gatsbyImageData(width: 400)
                    }
                    alt
                  }
                  authorInfo
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const quotes = data.allSanityTestimonial.edges
        return (
          <div className="mx-auto max-w-6xl my-14 custom-quotes  text-center w-full">
            <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {props.title}
            </h2>
            <p className="md:w-2/4	sm:w-full mx-auto my-8">
              {props.introduction}
            </p>
            <div className="block mb-6 mx-auto w-auto float-none text-center">
              <Link
                to="/quotes"
                className="mx-auto  float-none px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search All Quotes
              </Link>
            </div>
            <Carousel
              showIndicators={false}
              showThumbs={false}
              stopOnHover
              infiniteLoop
              emulateTouch
              autoPlay
              showStatus={false}
              showArrows={false}
              interval={5000}
            >
              {quotes.slice(0, 40).map((quote, i) => (
                <div>
                  <QuoteComponent key={i} data={quote.node} />
                </div>
              ))}
            </Carousel>
          </div>
        )
      }}
    />
  )
}
