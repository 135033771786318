// https://tailwindui.com/components/marketing/sections/feature-sections
// Simple dark with menu button on left
import React from 'react'
import SplitTwoTone from './layouts/splitTwoTone'
import SplitWithImage from './layouts/splitWithImage'

const Jotform = (props) => {
  const { layout } = props
  switch (layout) {
    case 'splitTwoTone':
      return <SplitTwoTone {...props} />
    case 'splitWithImage':
      return <SplitWithImage {...props} />
    default:
      return <SplitTwoTone {...props} />
  }
}

export default Jotform
