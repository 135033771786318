import React from 'react'
import iconMap from './icons'

function renderCell(plan, feature) {
  const featureValue = plan.featuresValues.find(
    (featValue) => featValue.feature.id === feature.id
  )

  if (!featureValue) {
    return iconMap.dash
  }

  const icon = iconMap[featureValue.icon]

  return (
    <div className="flex items-center">
      {icon && <span className="mr-5 block"> {icon}</span>}
      {featureValue.value && (
        <span className="block text-sm text-gray-700">
          {featureValue.value}
        </span>
      )}
    </div>
  )
}

function renderRows(plans, currentCategory) {
  return currentCategory.features.map((feature, i) => (
    <tr key={i}>
      <th
        className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
        scope="row"
      >
        {feature.title}
      </th>
      {plans.map((plan, x) => (
        <td key={x} className="py-5 px-6">
          {renderCell(plan, feature)}
        </td>
      ))}
    </tr>
  ))
}

export default function PricingComparisonTableLarge(props) {
  const { plans, categories } = props
  return (
    <div className="hidden lg:block">
      <table className="w-full h-px table-fixed">
        <caption className="sr-only">Pricing plan comparison</caption>
        <thead>
          <tr>
            <th
              className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
              scope="col"
            >
              <span className="sr-only">Feature by</span>
              <span>Plans</span>
            </th>

            {plans.map((plan, i) => (
              <th
                key={i}
                className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                scope="col"
              >
                {plan.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border-t border-gray-200 divide-y divide-gray-200">
          <tr>
            <th
              className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
              scope="row"
            >
              Pricing
            </th>
            {plans.map((plan, i) => (
              <td key={i} className="h-full py-8 px-6 align-top">
                <div className="relative h-full table">
                  <p>
                    <span className="text-4xl font-extrabold text-gray-900">
                      {plan.cost}
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      /{plan.costType}
                    </span>
                  </p>
                  <p className="mt-4 mb-16 text-sm text-gray-500">
                    {plan.description}
                  </p>
                  <a
                    href="#"
                    className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Get {plan.title}
                  </a>
                </div>
              </td>
            ))}
          </tr>
          {categories.map((category, i) => (
            <React.Fragment key={i}>
              <tr>
                <th
                  className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                  colSpan={plans.length + 1}
                  scope="colgroup"
                >
                  {category.title}
                </th>
              </tr>
              {renderRows(plans, category)}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-t border-gray-200">
            <th className="sr-only" scope="row">
              Choose your plan
            </th>
            {plans.map((plan, i) => (
              <td key={i} className="pt-5 px-6">
                <a
                  href="#"
                  className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Get {plan.title}
                </a>
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}
