import React from 'react'

const SimpleCentered = (props) => {
  const FauxTitle = (
    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
      {props.title}
    </p>
  )

  const Title = (
    <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
      {props.title}
    </h2>
  )

  const PrimaryTitle = (
    <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
      {props.title}
    </h1>
  )

  const PreTitle = (
    <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
      {props.preTitle}
    </h2>
  )

  const PrimaryPreTitle = (
    <h1 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
      {props.preTitle}
    </h1>
  )

  const TitleToggle = props.primaryHeader ? PrimaryTitle : Title
  const PreTitleToggle = props.primaryHeader ? PrimaryPreTitle : PreTitle
  return (
    <div className="bg-white">
      <div
        className={`max-w-7xl mx-auto ${
          props.size === 'small' ? 'py-8 sm:py-12' : 'py-16 sm:py-24'
        } px-4 sm:px-6 lg:px-8`}
      >
        <div className={props.alignment === 'center' && `text-center`}>
          {props.preTitle ? (
            <>
              {PreTitleToggle}
              {FauxTitle}
              <p
                className={`max-w-xl mt-5 ${
                  props.alignment === 'center' && 'mx-auto'
                } text-xl text-gray-500`}
              >
                {props.text}
              </p>
            </>
          ) : (
            <>
              {props.preTitle ? FauxTitle : TitleToggle}
              <p
                className={`max-w-xl mt-5 ${
                  props.alignment === 'center' && 'mx-auto'
                } text-xl text-gray-500`}
              >
                {props.text}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SimpleCentered
