import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import featuredImage from '../../studio/schemas/objects/featuredImage'

// function getFeaturedImage(rawItem, pages) {
//   const found = pages.find((page) => page.node._id === rawItem.page._id)
//   return found?.node?.featuredImage
// }

export default function ContentGrid(props) {
  const items = props.grid
  return (
    <StaticQuery
      query={graphql`
        query DidntMakeQuery {
          allSanityPost(
            filter: {
              category: { _id: { eq: "d4547ca1-eba8-47c6-891f-9cf2a57c4d06" } }
            }
          ) {
            edges {
              node {
                _id
                title
                slug {
                  current
                }
                color {
                  title
                }
                featuredImage {
                  asset {
                    id
                    gatsbyImageData(width: 450)
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const contentGrid = data.allSanityPost.edges
        return (
          <div className="relative max-w-7xl mx-auto">
            <div className="mt-12 max-w-lg mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
              {contentGrid.map((item, i) => {
                const image = getImage(
                  item.node.featuredImage?.asset?.gatsbyImageData
                )
                return (
                  <Link
                    to={`/investments/${item.node.slug?.current}`}
                    key={`item-${i}`}
                  >
                    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                      <div className="flex-shrink-0">
                        {item.node.featuredImage ? (
                          <div className="h-48 w-full object-cover">
                            <GatsbyImage
                              className="h-48 w-full object-cover"
                              image={image}
                              alt="alt"
                            />
                          </div>
                        ) : (
                          <img
                            className="h-48 w-full object-cover"
                            src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixqx=DGp9TbiLnL&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="prose flex-1 bg-white p-6 flex flex-col flex-start">
                        <div className="flex items-center flex-start w-full ">
                          <div
                            className={`rounded-full h-5 w-5 mt-4 mr-4 color-${
                              item?.node?.color?.title || null
                            }`}
                          />
                          <div className="">
                            <h4 className="prose font-medium text-indigo-600 p-0 mt-0">
                              {item.node.title}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}
