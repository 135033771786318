// https://tailwindui.com/components/marketing/sections/feature-sections
import React from 'react'
import './grid.css'

const OffsetIcons = (props) => {
  const { gridItems } = props
  return (
    <div className=" max-w-7xl mx-auto relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
          {props.preTitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          {props.title}
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          {props.text}
        </p>
        <div className="mt-12">
          {gridItems ? (
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {gridItems.map((gridItem) => (
                <div className="pt-6" key={gridItem._key}>
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        {gridItem.icon ? (
                          <span
                            className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg iconBlock"
                            dangerouslySetInnerHTML={{ __html: gridItem.icon }}
                          />
                        ) : (
                          <span className="apply h-6 w-6 text-white">
                            {gridItem.number}
                          </span>
                        )}
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {gridItem.title}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {gridItem.itemText}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default OffsetIcons
